<template>
  <a-modal v-model:visible="innerVisible" @cancel="$emit('update:visible', false)" class="side-modal" okText='修改'
    title="修改名称" @ok="onSubmit">
    <a-form :model="form" ref="form" :rules="rules" :label-col="{span:4}" :wrapper-col="{span:19}">
      <a-form-item label="分组名称" ref="name" name="name">
        <a-input v-model:value="form.name" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import ChannelQrcodeGroup from "@/service/api/channelQrcodeGroup";

export default {
  name: "ChannelModalSiderEdit",
  components: {},
  props: {
    //活码组的id
    id: Number,
    visible: Boolean,
  },
  data() {
    const rules = {
      name: [
        {
          trigger: "blur",
          validator: async (rule, value) => {
            if (value.length > 10) {
              return Promise.reject("分组名称不能超过10");
            } else if (value.length <= 0) {
              return Promise.reject("请输入分组名称");
            }
          },
        },
        {
          pattern: /^[^\s]*$/,
          message: "不允许包含空格",
        },
      ],
    };
    return {
      rules: rules,
      innerVisible: false,
      form: {},
    };
  },

  computed: {},

  mounted() {},
  emits: ["update:visible", "created"],

  watch: {
    visible: function (newVisible) {
      this.innerVisible = newVisible;
      if (newVisible === true) {
        this.getCodeGroup();
      }
    },
  },
  methods: {
    async getCodeGroup() {
      console.log(this.id);
      this.form = await ChannelQrcodeGroup.get(this.id);
      console.log(this.form);
    },
    onSubmit() {
      this.$refs.form.validate().then(async () => {
        //调修改接口 传form这个对象
        const newCodeGroup = await ChannelQrcodeGroup.update({
          id: this.form.id,
          name: this.form.name,
        });
        this.$emit("created", newCodeGroup);
        this.$message.success("修改成功");
        this.$emit("update:visible", false);
      });
    },
  },
};
</script>
<style  scoped>
</style>