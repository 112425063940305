<template>
  <div class="search-header">
    <a-form layout="inline"
            :model="searchForm">
      <a-form-item>
        <a-input-search v-model:value="searchForm.name"
                        placeholder="请输入渠道活码名称"
                        style="width: 360px"
                        v-debounce="onSearchSubmit" />
      </a-form-item>
    </a-form>
    <router-link :to="{path:'createCode'}">
      <a-button type="primary"
                centered>
        <svg-icon type="icontianjia1" />新建活码
      </a-button>
    </router-link>
  </div>

  <div class="flex">
    <div class="table-sider">
      <a-menu v-model:selectedKeys="selectedKeys"
              mode="inline"
              @select="codeGroupClick">

        <div class="table-sider__add hover:text-admin-primary"
             @click.stop="isInputShow = !isInputShow">
          <svg-icon class="mr-8"
                    type="icontianjia1" />
          添加分组
        </div>

        <a-input v-model:value="groupName"
                 v-show="isInputShow"
                 class="table-sider__add-input"
                 placeholder="请输入分组"
                 @click.stop="isInputShow = true"
                 @keyup.enter="createCodeGroup"
                 @blur="createCodeGroup" />

        <a-menu-item class="table-sider__item"
                     key="">
          全部
          <div class="flex-shrink-0">
            {{ totalCodeGroupNum }}
            <svg-icon class="table-sider__icon opacity-0"
                      type="iconmore01" />
          </div>
        </a-menu-item>

        <a-menu-item v-for="Group in liveCodeGroup"
                     :key="Group.id"
                     class="table-sider__item">
          <div class="truncate"
               :title='Group.name'>{{Group.name}}</div>

          <div class="flex-shrink-0 ml-8">
            {{Group.groupCnt}}
            <a-dropdown>
              <svg-icon class="table-sider__icon"
                        type="iconmore01" />
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="codeGroupId = Group.id; eidtVisible = true">
                    编辑
                  </a-menu-item>
                  <a-menu-item @click="deleteCodeGroup(Group)">
                    删除
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </a-menu-item>
      </a-menu>
    </div>
    <a-table :columns="columns"
             :data-source="codeList"
             row-key="id"
             :scroll="{y: 'calc(100vh - 322px)'}"
             :pagination="pagination"
             :loading="tableLoading"
             @change="onPaginationChange">
      <template #showAvatar="{ record }">
        <a-image :src="record.avatar"
                 alt="渠道活码二维码"
                 width="56px"
                 height="56px"
                 :preview="false" />
      </template>

      <template #name="{ text }">
        <span class="line-clamp-2">{{ text }}</span>
      </template>

      <template #createdTime="{ text }">
        {{ $f.datetime(text, 'YYYY-MM-DD HH:mm') }}
      </template>

      <template #staffWorkWechatThirdAppUserId="{ record }">
        <div style="min-width: 100px; word-break: break-word;">
          <template v-for="(staff, index) in record.staffWorkWechatThirdAppUserId"
                    :key="index">
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="staff"></div>
            <span v-if="index !== record.staffWorkWechatThirdAppUserId.length - 1">, </span>
          </template>
        </div>
      </template>

      <template #tagList="{ record }">
        <a-tag v-for="tag in record.tagList"
               :key="tag">{{ tag }}</a-tag>
      </template>

      <template #action="{ record }">
        <span class="text-admin-primary cursor-pointer"
              @click="codeId = record.id; downLoadVisible = true">详情</span>
        <a-dropdown>
          <svg-icon class="ml-16 text-16 text-admin-primary"
                    type="iconmore"
                    @click.stop />
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <router-link :to="{path: 'statisticsCode', query:{ id: record.id}}">数据统计</router-link>
              </a-menu-item>
              <a-menu-item @click="downloadImg(record.avatar, record.name)">
                下载
              </a-menu-item>
              <a-menu-item>
                <router-link :to="{ path: 'editCode',query:{ id: record.id}}">编辑</router-link>
              </a-menu-item>
              <a-menu-item @click="deleteItem = record; deleteModalVisible = true">
                删除
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>

  <channel-modal-sider-edit v-model:visible="eidtVisible"
                            @created="getList"
                            :id="codeGroupId">
  </channel-modal-sider-edit>

  <channel-modal-detail v-model:visible="downLoadVisible"
                        :id="codeId"
                        @changed="getList">
  </channel-modal-detail>

  <delete-modal v-model:visible="deleteModalVisible"
                title="删除渠道活码"
                :delete-item="deleteItem"
                @delete="deleteCode">
  </delete-modal>

</template>

<script>
import _ from "lodash";
import { defineComponent, reactive, ref } from "vue";
import router from "@/router";
import { Image } from "ant-design-vue";

import ChannelModalDetail from "./ChannelModalDetail.vue";
import ChannelModalSiderEdit from "./ChannelModalSiderEdit.vue";
import DeleteModal from "@/components/DeleteModal";
import SvgIcon from "@/components/SvgIcon";

import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import ChannelQrcodeGroup from "@/service/api/channelQrcodeGroup";
import ChannelQrcode from "@/service/api/channelQrcode";

import { downloadImg } from "@/global";

export default defineComponent({
  name: "ChannelTable",

  components: {
    ChannelModalDetail,
    ChannelModalSiderEdit,
    DeleteModal,
    SvgIcon,
    AImage: Image,
  },

  setup() {
    const searchForm = reactive({
      name: "",
      groupId: "",
    });
    const selectedKeys = ref([]);

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, ChannelQrcode.search);

    return {
      searchForm,
      selectedKeys,
      codeList: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    };
  },

  data() {
    const columns = [
      {
        title: "二维码",
        key: "showAvatar",
        width: 80,
        customCell: this.customCell,
        slots: { customRender: "showAvatar" },
      },
      {
        title: "名称",
        dataIndex: "name",
        key: "name",
        width: "20%",
        slots: { customRender: "name" },
      },
      {
        title: "添加次数",
        dataIndex: "accumCnt",
        key: "accumCnt",
        width: 90,
      },
      {
        title: "创建时间",
        dataIndex: "createdTime",
        key: "createdTime",
        width: 120,
        slots: { customRender: "createdTime" },
      },
      {
        title: "使用成员",
        key: "staffWorkWechatThirdAppUserId",
        slots: { customRender: "staffWorkWechatThirdAppUserId" },
      },
      {
        title: "标签",
        key: "tagList",

        slots: { customRender: "tagList" },
      },
      {
        title: "操作",
        key: "action",
        width: 120,
        slots: { customRender: "action" },
      },
    ];

    const liveCodeGroup = [];

    return {
      downloadImg,

      columns,
      liveCodeGroup,
      codeGroupId: 0,
      codeId: 0,
      groupName: "",
      isInputShow: false,
      //编辑modal
      eidtVisible: false,
      //活码详情modal
      downLoadVisible: false,
      //删除活码modal
      deleteModalVisible: false,
      deleteItem: {},
    };
  },

  computed: {
    totalCodeGroupNum() {
      let total = 0;
      _.forEach(this.liveCodeGroup, function (value) {
        total += value.groupCnt;
      });
      return total;
    },
  },

  created() {
    this.getList();
    document.addEventListener("click", () => {
      this.isInputShow = false;
    });
  },
  methods: {
    async getList() {
      this.liveCodeGroup = await ChannelQrcodeGroup.getList();
      this.searchForm.groupId
        ? this.selectedKeys.push(Number(this.searchForm.groupId))
        : (this.selectedKeys = [""]);
    },

    codeGroupClick(res) {
      this.searchForm.groupId = res.key;
      this.onSearchSubmit();
    },

    async deleteCodeGroup(Group) {
      let { id, groupCnt } = Group;
      if (groupCnt > 0) {
        this.$message.error("分组还存在活码，无法删除");
        return;
      }

      await ChannelQrcodeGroup.delete(id);
      this.$message.success("删除成功");
      this.getList();
    },

    async createCodeGroup() {
      const newCodeGroupName = this.groupName;

      if (!_.trim(newCodeGroupName)) {
        return;
      }

      const reg = /^[^\s]*$/;
      if (!reg.test(newCodeGroupName)) {
        this.$message.error("不允许包含空格");
        return;
      }

      if (newCodeGroupName.length > 10) {
        this.$message.error("分组名称不能超过10字符");
        return;
      }

      await ChannelQrcodeGroup.create({
        name: newCodeGroupName,
      });

      this.$message.success("创建成功");
      this.getList();
      this.groupName = "";
      this.isInputShow = false;
    },

    async deleteCode(record) {
      await ChannelQrcode.delete(record.id);
      this.deleteModalVisible = false;
      this.$message.success("删除成功");

      this.fetchPaginationData();
      this.getList();
    },

    customCell(record) {
      return {
        onclick: () => {
          this.codeId = record.id;
          this.downLoadVisible = true;
        },
      };
    },
  },
});
</script>
<style lang="less" scoped>
.ant-table-wrapper {
  margin-left: @spacing-6x;
  flex: 1;
}
</style>